// prettier-ignore
// this file is automatically generated by git.version.ts script
export const Build = {
  appVersion: `7.3.11`,
  branch: `MAIN`,
  lastCommit: `96a5520`,
  lastCommitUrl: `https://dev.azure.com/ZEISSgroup-VIS/VIS%20-%20Program%200/_git/Frontend.VisionPortal/commit/96a5520cebf99714f56c4f71cb5af60fc0757fed`,
  lastCommitHash: `96a5520cebf99714f56c4f71cb5af60fc0757fed`,
  lastCommitTime: `2024-09-17T11:42:00Z`,
  lastCommitMessage: `Merged PR 46456: TST Release v6.2.3

# PR completion

- [X] Tested functionality on preview deployment
- [X] Provided detailed changelog
- [X] Provided screenshots of all optical changes (before + after)

# Included module PRs

- !44437
- !45370
- !45825
- !44446

# General description

Updated TST to v6.2.3.

# Changelog

- [🚀] Adjusted set carrier drop down in carrier tab.
- [🚀] Adding bulk child shipments in conship is now possible by either SSCC18 or CTN, based on lane type.
- [🚀] Scanning, adding and removing shipment items is now only possible by serial number.
- [💎] Updated @zeiss/ng-vis-common 6.0.1 -> 6.3.0
- [📋] The custom translation loader is now loaded from @zeiss/ng-vis-common.

Related work items: #450539, #451225, #452567, #452598, #452885`,
  lastCommitAuthor: `Alexander Dimitrov`
};
