import { Inject, Injectable } from '@angular/core';
import { LanguageServiceProvider, SettingsServiceProvider, SystemLanguage } from '@zeiss/ng-vis-common/types';
import { BehaviorSubject } from 'rxjs';
import { App } from 'src/environments/shared';
import { LogPre } from './language';
import { SETTINGS_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { NgVisVpSettings } from '../settings/settings';

export type ModulesWithLanguage =
	| 'admin'
	| 'ddp'
	| 'dev'
	| 'logs'
	| 'lpp'
	| 'mddb'
	| 'omr'
	| 'otd'
	| 'settings'
	| 'splash'
	| 'status'
	| 'task'
	| 'tst'
	| 'gbp'
	| 'utilities';

@Injectable({ providedIn: 'root' })
export class LanguageService implements LanguageServiceProvider<ModulesWithLanguage> {
	private lang?: SystemLanguage;
	private langNavigator?: SystemLanguage;
	private auto = false;
	private initiated = false;
	public supportedLanguages = {} as Record<ModulesWithLanguage, SystemLanguage[]>;
	readonly Language$ = new BehaviorSubject<SystemLanguage | undefined>(undefined);

	constructor(@Inject(SETTINGS_SERVICE) public settingsService: SettingsServiceProvider<NgVisVpSettings>) {}

	public initLanguageSettings() {
		if (this.initiated) {
			console.error(LogPre, `Can't initiate language settings twice.`);
			return;
		}
		this.initiated = true;
		this.mapNavigatorLanguage();

		console.debug(LogPre, `User wants: ${this.settingsService.settings.SystemLanguage}`);
		console.debug(LogPre, `OS setting: ${navigator.language} (parsed: ${this.langNavigator})`);

		if (
			(this.settingsService.settings.SystemLanguage === 'os-setting' ||
				!this.settingsService.settings.SystemLanguage) &&
			(this.LanguageOs || this.LanguageOs === App.DefaultLanguage)
		) {
			console.debug(LogPre, `Setting to os-setting`);
			this.Auto = true;
		} else if (
			this.settingsService.settings.SystemLanguage &&
			this.settingsService.settings.SystemLanguage !== 'os-setting'
		) {
			console.debug(LogPre, `Setting to user's wish`);
			this.Language = this.settingsService.settings.SystemLanguage;
		} else {
			console.debug(LogPre, `Setting to default`);
			this.Language = App.DefaultLanguage;
		}
	}

	get Language() {
		return this.lang ?? App.DefaultLanguage;
	}

	set Language(lang: SystemLanguage) {
		this.lang = lang;

		this.Language$.next(this.lang);
		console.debug(LogPre, `Set to: ${this.lang}`);

		if (!this.Auto && this.settingsService.settings.SystemLanguage !== this.lang) {
			this.settingsService.settings = { SystemLanguage: this.lang };
		}
	}

	get LanguageOs(): SystemLanguage | undefined {
		return this.langNavigator;
	}

	get Auto() {
		return this.auto;
	}

	set Auto(auto: boolean) {
		if (auto && (this.LanguageOs || this.LanguageOs === App.DefaultLanguage)) {
			this.auto = auto;
			this.Language = this.LanguageOs;
			if (this.settingsService.settings.SystemLanguage !== 'os-setting') {
				this.settingsService.settings = { SystemLanguage: 'os-setting' };
			}
		} else {
			this.auto = false;
		}
	}

	private mapNavigatorLanguage() {
		switch (navigator.language) {
			case 'en':
				this.langNavigator = SystemLanguage['en-GB'];
				break;

			case 'de':
				this.langNavigator = SystemLanguage['de-DE'];
				break;

			default:
				this.langNavigator = App.DefaultLanguage;
				break;
		}
	}
}
