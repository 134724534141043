export const LogPre = '💬 [Language]';

export interface Country {
	name: string;
	alpha2: string;
	countryCode: string;
}

export interface CountryDTO {
	n: string;
	a2: string;
	cc: string;
}

export interface Language {
	language: number;
	rfc1766: string;
	text: string;
	statusText: DbText;
}

export interface DbText {
	language: number;
	text: string;
	description: string;
}

export interface Translation {
	detectedLanguage: TranslationDetectedLanguage[];
	translations: TranslationText[];
}

export interface TranslationText {
	text: string;
	to: string;
}

export interface TranslationDetectedLanguage {
	language: string;
	score: number;
}
